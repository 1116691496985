// Colors
$primaryColor: #000;
$secondaryColor: #FFF;
$tertiaryColor: #232F3E;
$quaternaryColor: #FEBD69;

// Fonts
$primaryFont: 'Open Sans', sans-serif;
$secondaryFont: 'Open Sans', sans-serif;

// Margins, Paddings, Shadows ...
$padding: 15px;
$margin: $padding;
$borderRadius: 4px;

$textShadow: 2px 2px 3px rgba(0,0,0,0.8);
$boxShadow: 3px 3px 9px 0px rgba(0, 0, 0, 0.8);

$transition: 0.2s;

// Media Queries
$mobile: 480px;
$tablet: 768px;
$desktop: 1170px;