header {
    width: 100%;
    background-color: $tertiaryColor;

    & > section {
        width: 100%;
        max-width: $desktop;
        margin: 0 auto;
        padding: $padding;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;

        .shop-title {
            color: $secondaryColor;
            font-size: 2.1em
        }
    }
}